
import Vue from 'vue';
import { format, parse } from 'date-fns';
import { Campaign } from '../../../types/advertisers';

export default Vue.extend({
  name: 'CampaignOnlyList',
  props: ['campaignData', 'tacticData', 'campaginSearch', 'dropdownList'],
  components: {
    SearchBar: () => import('./searchBar.vue'),
    ExportMenu: () => import('../../components/toolbar/menus/exportMenu.vue'),
  },
  data: (): {
    selectedItem: string;
    selectedProduct: string;
    search: string;
  } => ({
    selectedItem: null,
    selectedProduct: '',
    search: '',
  }),
  computed: {
    data() {
      if (!this.campaignData || !Array.isArray(this.campaignData) || this.campaignData.length === 0) return null;
      return this.campaignData;
    },
    loading(): boolean {
      return this.$store.state?.advertiserView?.loadingCampaigns;
    },
    campaignCount(): string {
      return this.data?.length ? `(${this.data.length})` : null;
    },
    campaignRecordCount(): string {
      return this.data?.length >= 100 ? 'Showing the first 100 campaigns. Please search to narrow your results.' : null;
    },
    message(): string {
      return !this.data ? 'No campaigns found' : '';
    },
  },
  methods: {
    selectedCampaign(campaign: Campaign): void {
      this.$emit('selectedCampaign', campaign);
    },
    currentSection(analyticsType) {
      if (!analyticsType) return null;
      const filteredLayout = this.tacticData?.filter(section => section.id === analyticsType?.toLowerCase());
      return filteredLayout?.length > 0 ? filteredLayout[0] : null;
    },
    formatDate(date: string): string {
      try {
        if (!date) return '';
        const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(parsedDate, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    formatData(data) {
      const startdate = this.formatDate(data?.Start_date);
      const enddate = this.formatDate(data?.End_date);
      return {
        advertiserId: data.advertiserId,
        advertiserName: data.advertiserName,
        campaigns: data.campaignId,
        startdate,
        enddate,
        daterange: 'alltime',
      };
    },
    updateSearch(params: { [key: string]: string }): void {
      this.search = params || '';
      this.$emit('updateSearch', {
        search: this.search,
        product: this.selectedProduct === 'emailsi' ? 'SITEIMPACT' : this.selectedProduct,
      });
    },
    updateProduct(): void {
      this.$emit('updateSearch', {
        search: this.search ? this.search : '',
        product: this.selectedProduct === 'emailsi' ? 'SITEIMPACT' : this.selectedProduct?.toUpperCase(),
      });
    },
  },
  watch: {
    campaignData(): void {
      this.selectedItem = null;
      // this.search = null;
    },
  },
});
